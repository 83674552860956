<template>
  <div class="page">
    <div class="c-jumio">
      <div class="c-jumio__description">
        <div class="c-jumio__content">
          <img class="c-jumio__img" src="@/assets/images/old-alpha/jumio-failed.png" alt="jumio-failed">
          <span class="c-jumio__failed-text">Sorry</span>
        </div>
        <p class="c-jumio__desc">There's an issue with the verification of your documents.<br>
        Please try again in your Account - Identity Verification page.</p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'JumioFailed',
  components: {
  },
  data() {
    return {
    }
  },
  mounted:function() {
  }
}
</script>
